import React, {useContext, useState} from 'react';
import "./ProfileReferral.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {CLIENT_URL} from "../../http";
import CopyNotification from "../../components/CopyNotification/CopyNotification";
import telegramImg from "../../img/refTelegram.svg";
import vkImg from "../../img/refVk.svg";
import whatsappImg from "../../img/refWhatsapp.svg";
import twitterImg from "../../img/refTwitter.svg";

function ProfileReferral({setSelectedItem, setSelectedButton}) {
	const {store} = useContext(Context);
	const [notification, setNotification] = useState({ visible: false, message: '' });

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text)
			.then(() => {
				setNotification({ visible: true, message: 'Текст скопирован в буфер обмена' });
			})
			.catch((err) => {
				console.error('Не удалось скопировать текст: ', err);
			});
	};
	const handleNotificationClose = () => {
		setNotification({ visible: false, message: '' });
	};
	return (
		<div className="referral">
			<div className="__header">
				<div className="__wrapper">
					<div className="__title">
						<h1>Пригласите друзей. Получайте скидки при обмене.</h1>
						<p>Получите до 1.5% скидок от суммы за каждую сделку</p>
					</div>
					{
						store.user.isVerified || store.user.isActivated ?
							<div className="__ref_info">
								<div className="__benefits">
									<div className="__receive">
										<p>Являетесь ли вы рефералом:</p>
										<h5>{store.user.referrer > 0 ? 'Да' : 'Нет'}</h5>
									</div>
									<div className="__receive">
										<p>Количество ваших рефералов:</p>
										<h5>{store.user.referrals ? `${store.user.referrals} ≈ -${Math.min(store.user.referrals * 0.1, 1.5)}%` : 0 }  </h5>
									</div>
								</div>
								<div className="__ref_id">
									<h3>ID реферала</h3>
									<div className="__item">
										{store.user.id}
									</div>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
									     cursor="pointer"
									     className="__copy" onClick={() => copyToClipboard(store.user.id)}>
										<path fillRule="evenodd" clipRule="evenodd"
										      d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z" fill="currentColor"></path>
									</svg>
								</div>
								<div className="__ref_link">
									<h3>Реферальная ссылка</h3>
									<div className="__item">
										https://dexpay.ru....={store.user.id}
									</div>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
									     cursor="pointer"
									     className="__copy"
									     onClick={() => copyToClipboard(`${CLIENT_URL}/registration?ref=${store.user.id}`)}>
										<path fillRule="evenodd" clipRule="evenodd"
										      d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z" fill="currentColor"></path>
									</svg>
								</div>
								<div className="__share_links">
									<a href={`https://t.me/share/url?url=https://dexpay.ru/registration?ref=${store.user.id}&text=Нажмите на ссылку чтобы создать аккаунт`}
									   target="_blank" rel="noopener noreferrer">
										<img src={telegramImg} alt="img"/>
									</a>
									<a href={`https://api.whatsapp.com/send?text=Нажмите на ссылку чтобы создать аккаунт: ${CLIENT_URL}/registration?ref=${store.user.id}`}
									   target="_blank" rel="noopener noreferrer">
										<img src={whatsappImg} alt="img"/>
									</a>
									<a href={`https://vk.com/share.php?url=${CLIENT_URL}/registration?ref=${store.user.id}&title=Регистрация&description=Нажмите на ссылку чтобы создать аккаунт`}
									   target="_blank" rel="noopener noreferrer">
										<img src={vkImg} alt="img"/>
									</a>
									<a href={`https://x.com/intent/tweet?url=${CLIENT_URL}/registration?ref=${store.user.id}&text=Нажмите на ссылку чтобы создать аккаунт`}
									   target="_blank" rel="noopener noreferrer">
										<img src={twitterImg} alt="img"/>
									</a>
								</div>
							</div> :
							<div className="__ref_info __verification_prompt">
								<h3>Для получения доступа к реферальной программе, необходимо пройти верификацию уровня "Верифицированный"  и совершить первый обмен</h3>
								<button className="__verify_button" onClick={() => {
									setSelectedButton('account')
									setSelectedItem('account')
								}}>
									Пройти верификацию
								</button>
							</div>
					}

				</div>
			</div>
			<div className="__rules">
				<h1>Правила реферальной программы</h1>
				<div className="section">
					<p>1. Участие в реферальной программе</p>
					<ul>
						<li>1.1. В реферальной программе могут участвовать зарегистрированные пользователи платформы
							Dexpay.
						</li>
						<li>1.2. Для участия необходимо отправить личную реферальную ссылку своим друзьям, коллегам или
							знакомым.
						</li>
					</ul>
				</div>

				<div className="section">
					<p>2. Награды за рефералов</p>
					<ul>
						<li>2.1. За каждого нового пользователя, зарегистрировавшегося по вашей реферальной ссылке, вы
							получаете скидку на обмен в размере 0,1%.
						</li>
						<li>2.2. Скидка начисляется только после выполнения рефералом первой обменной операции и
							получения статуса Верифицированный пользователь.
						</li>
					</ul>
				</div>

				<div className="section">
					<p>3. Лимиты и условия использования скидки</p>
					<ul>
						<li>3.1. Максимальная накопленная скидка за всех рефералов не может превышать 1,5%.</li>
						<li>3.2. Скидка действует на все операции обмена, доступные на платформе Dexpay.</li>
						<li>3.3. Скидка не является фиксированной и уменьшается, если ваш общий баланс реферальных
							начислений сокращается из-за исключения пользователей из программы (например, за нарушение
							правил).
						</li>
					</ul>
				</div>

				<div className="section">
					<p>4. Условия для рефералов</p>
					<ul>
						<li>4.1. Рефералы, зарегистрировавшиеся по вашей ссылке, получают доступ к стандартным условиям
							использования платформы Dexpay.
						</li>
					</ul>
				</div>

				<div className="section">
					<p>5. Дополнительные условия</p>
					<ul>
						<li>5.1. Администрация Dexpay оставляет за собой право изменять условия реферальной программы с
							предварительным уведомлением пользователей.
						</li>
						<li>5.2. Выявление мошеннических действий, включая создание поддельных аккаунтов для получения
							скидок, может привести к удалению начисленных бонусов и блокировке учетной записи.
						</li>
						<li>5.3. Вопросы, связанные с программой, можно адресовать в службу поддержки.</li>
					</ul>
				</div>
			</div>
			<CopyNotification message={notification.message} visible={notification.visible}
			                  onClose={handleNotificationClose}/>
		</div>
	);
}

export default observer(ProfileReferral);